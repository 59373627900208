<template>
    <AppContainer>
        <AddWalkIn/>
    </AppContainer>
</template>

<script>
  import AppContainer from '@/components/containers/DashboardContainer.vue'
  import AddWalkIn from '@/components/dashboard/booking/AddWalkIn.vue'
  export default {
    components: {
      AppContainer,
      AddWalkIn
    }
  }
</script>