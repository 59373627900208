<template>
    <div class="email-campaign">
        <div class="email-deatils">
            <h3>Add New WalkIn</h3>
            <div class="row mb-3">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Choose Customer </label>
                        <select v-model="walkin_.user" :class="[v$.walkin_.user.$error ? 'border-danger': '']" class="form-control">
                            <option value="">Choose Customer</option>
                            <option v-for="customer in customers" :key="customer.id" :value="customer">{{customer.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group pt-md-4 pt-0 mt-md-1 mt-0">
                        <a href="#!" class="rem-btn" data-bs-toggle="modal" data-bs-target="#addCustomerModal">Add New Customer <fa class="ml-2" icon="plus"/></a>
                    </div>
                </div>
            </div>
            <!-- <div class="list-intersets">
                <div class="list-cars" style="width: 100px">
                    <a href="#" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#exampleModal15"><img class="img-fluid" src="@/assets/images/profile/profile.svg" alt="" style="width: 85px"></a>
                </div>
                <div class="intersets-con">
                    <h5>Mehran Shah</h5>
                    <h4>mehran@ondaq.com</h4>
                    <p>+121222222222</p>
                </div>
            </div> -->
            <div class="card border px-2 mt-3 mb-4" v-if="walkin_.user">
                <!-- <div class="card-header">
                    <h4>Personal Infomation</h4>
                </div> -->

                <div class="card-body">
                    <div class="user-pro">
                        <div class="user-setting">
                            <div class="user-img">
                                <div class="button-wrapper">
                                    <img v-if="walkin_.user.picture" class="img-fluid" :src="walkin_.user.picture" alt="">
                                    <img v-else class="img-fluid" src="@/assets/images/profile/profile.svg" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="setting-tables">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{{walkin_.user.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{{walkin_.user.email}}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone</td>
                                        <td>{{walkin_.user.phone}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-sm-6 col-md-4 cursor-pointer">
                    <div class="counters" :class="walkin_.booking_type == 2 ? 'type-active':v$.walkin_.booking_type.$error ? 'border-danger':''" v-on:click="changeType(2)">
                        <div class="thamnils">
                            <img v-if="walkin_.booking_type == 2" class="img-fluid" src="@/assets/images/dashboard/appointment_white.svg" alt="">
                            <img v-else class="img-fluid" src="@/assets/images/dashboard/appointment.svg" alt="">
                        </div>
                        <div class="counter-num">
                            <h6>Booking</h6>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-4 cursor-pointer">
                    <div class="counters" :class="walkin_.booking_type == 1 ? 'type-active':v$.walkin_.booking_type.$error ? 'border-danger':''" v-on:click="changeType(1)">
                        <div class="thamnils">
                            <img v-if="walkin_.booking_type == 1" class="img-fluid" src="@/assets/images/dashboard/queue_white.svg" alt="">
                            <img v-else class="img-fluid" src="@/assets/images/dashboard/queue.svg" alt="">
                        </div>
                        <div class="counter-num">
                            <h6>Queue</h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row mb-4">
                <div class="col-md-6 col-12 mb-3">
                    <div class="plan-box" :class="walkin_.booking_type == 2 ? 'plan-active':v$.walkin_.booking_type.$error ? 'border-danger':''" v-on:click="walkin_.booking_type = 2">
                        <div class="plan-icon">
                            <img src="@/assets/images/icons/individual.png" />
                            <span class="check-mark"><img src="@/assets/images/icons/list-icon.png" /> <i class="fa fa-check" aria-hidden="true"></i></span>
                        </div>
                        <h4 class="plan-title">Booking</h4>
                        <p>Go with booking</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 mb-3">
                    <div class="plan-box" :class="walkin_.booking_type == 1 ? 'plan-active':v$.walkin_.booking_type.$error ? 'border-danger':''" v-on:click="walkin_.booking_type = 1">
                        <div class="plan-icon">
                            <img src="@/assets/images/dashboard/queue.svg" />
                            <span class="check-mark"><img src="@/assets/images/icons/list-icon.png" /> <i class="fa fa-check" aria-hidden="true"></i></span>
                        </div>
                        <h4 class="plan-title">Queue</h4>
                        <p>Go with queue</p>
                    </div>
                </div>
            </div> -->

            <div class="overview-tabs-detail" v-if="walkin_.booking_type == 1">
                <!-- <div class="form-row">
                    <div class="form-group col-12">
                        <label>Additional Note <small>(optional)</small> </label>
                        <textarea v-model="walkin_.note" class="form-control" placeholder="Do you have any special requests or ideas to share with your service provider?" rows="4" style="resize: none"></textarea>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-12">
                        <h5 class="mb-2">Choose a Professional</h5>
                    </div>
                    <div class="col-12 col-md-6 mb-2" v-for="(employee,index) in employees" :key="index" >
                        <div class="card emp-card" v-on:click="getServices(employee,'booking')" :class="walkin_.professional.id == employee.id ? 'active':''">
                            <div class="card-body p-3 mt-0">
                                <div class="booking-vendor-img" style="background: #0000 url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/user-icon.jpg') repeat scroll 50% 50% / cover padding-box border-box;"></div>
                                <div id="DIV_10">
                                    <a href="javascript:void(0);" id="A_11">{{employee.user.name}}</a>
                                    <div id="DIV_12">
                                        <span id="SPAN_14" ng-if="slot.service">{{employee.profession.title}}</span>  <br>
                                        <span id="SPAN_14" >Est Time: 5min</span>            
                                    </div>
                                </div>
                            </div>
                            <fa icon="check-circle" class="fa-lg" />
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="row mt-3">
                            <label class="mb-2">Select Services</label>

                            <div v-for="(service,index) in walkin_.services" :key="index" class="form-group col-md-6">
                                <label class="con-company" :class="service.selected?'currnt':''">
                                    <span>{{service.title}}</span>
                                    <span class="float-end ms-5">${{service.cost}}</span>
                                    <span class="float-end">{{service.duration}} min</span>
                                    <input v-model="service.selected" :true-value="true" :false-value="false" type="checkbox" name="checkbox" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>

            <form action="" class="mb-4" v-if="walkin_.booking_type == 2">
                <!-- <div class="form-row">
                    <div class="col-12 col-md-8">
                        <div class="form-group">
                            <label>Email / Phone <span style="font-size:11px">(Find the professional before you proceed)</span></label>
                            <div class="d-flex email-search">
                                <input v-model="walkin_.email_phone" :class="[v$.walkin_.email_phone.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Enter email / phone">
                                <a v-on:click.prevent="searchUser" href="#" class="rem-btn" >Search</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label>First Name</label>
                            <div class="d-flex email-search">
                                <input v-model="walkin_.user.first_name" :class="[v$.walkin_.user.first_name.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="John">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label>Last Name</label>
                            <div class="d-flex email-search">
                                <input v-model="walkin_.user.last_name" :class="[v$.walkin_.user.last_name.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Doe">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label>Email</label>
                            <div class="d-flex email-search">
                                <input v-model="walkin_.user.email" :class="[v$.walkin_.user.email.$error ? 'border-danger': '']" type="email" class="form-control" placeholder="example@gmail.com">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label>Phone Number</label>
                            <div class="d-flex email-search">
                                <input v-model="walkin_.user.phone" :class="[v$.walkin_.user.phone.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="+1213143132322">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-6 col-md-6 col-lg-6">
                        <label>Gender </label>
                        <select v-model="walkin_.user.gender" :class="[v$.walkin_.user.gender.$error ? 'border-danger': '']" class="form-control">
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                </div> -->
                <div class="form-row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Date</label>
                            <Datepicker v-model="walkin_.date" :class="[v$.walkin_.date.$error ? 'border-danger': '']" :enableTimePicker="false" :autoApply="true" placeholder="Select Date"></Datepicker>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Time</label>
                            <div>
                                <vue-timepicker v-model="walkin_.time_slot" :class="[v$.walkin_.time_slot.$error ? 'border-danger': '']" format="hh:mm A" :minute-interval="5" placeholder="hh:mm a"></vue-timepicker>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="form-group">
                            <label>Service List</label>
                            <Multiselect v-model="walkin_.services" :options="services" :canDeselect="false" mode="multiple" :groupSelect="false" class="form-control" placeholder='Search Service..' :groups="true" :searchable="true" :hideSelected="false" :closeOnSelect="true">
                                <template v-slot:multiplelabel="{ values }">
                                    <div class="multiselect-multiple-label">
                                        <span v-for="(s,index) in values" :key="index">{{s.label}}<span v-if="values.length-1 != index ">, </span></span>
                                    </div>
                                </template>
                            </Multiselect>
                        </div>
                    </div> -->
                </div>

                <h5 class="mb-2">Where is the service rendered?</h5>
                <div class="form-row">
                    <div class="form-group col-12">
                        <div class="form-check form-check-inline">
                            <input v-model="walkin_.render_location" value="1" class="form-check-input" type="radio" name="rl" id="rl1" />
                            <label class="form-check-label" for="rl1"> Business location </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-model="walkin_.render_location" value="2" class="form-check-input" type="radio" name="rl" id="rl2" />
                            <label class="form-check-label" for="rl2"> Client preference </label>
                        </div>
                    </div>
                </div>
                <div class="form-row" v-if="walkin_.render_location == '2'">
                    <div class="form-group col-md-6">
                        <input type="text" v-model="walkin_.rendered_address" :class="[v$.walkin_.time_slot.$error ? 'border-danger': '']" class="form-control" placeholder="Enter your address">
                    </div>
                    <div class="form-group col-md-6">
                        <input type="text"
                            class="form-control"
                            placeholder="Apt/Unit/Suite (optional)"
                            v-model="walkin_.rendered_address_apt" />
                    </div>
                    <span style="color:#DC143C; display:none">Whoops! this location is out of our service area.</span>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h5 class="mb-2">Choose a Professional</h5>
                    </div>
                    <div class="col-12 col-md-6 mb-2" v-for="(employee,index) in employees" :key="index" >
                        <div class="card emp-card" v-on:click="getServices(employee,'booking')" :class="walkin_.professional.id == employee.id ? 'active':''">
                            <div class="card-body p-3 mt-0">
                                <div class="booking-vendor-img" style="background: #0000 url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/user-icon.jpg') repeat scroll 50% 50% / cover padding-box border-box;"></div>
                                <div id="DIV_10">
                                    <a href="javascript:void(0);" id="A_11">{{employee.user.name}}</a>
                                    <div id="DIV_12">
                                        <span id="SPAN_14" ng-if="slot.service">{{employee.profession.title}}</span>  <br>
                                        <span id="SPAN_14" >Est Time: 5min</span>            
                                    </div>
                                </div>
                            </div>
                            <fa icon="check-circle" class="fa-lg" />
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="row mt-3">
                            <label class="mb-2">Select Services </label>
                            <div v-for="(service,index) in walkin_.services" :key="index" class="form-group col-md-6">
                                <label class="con-company" :class="service.selected?'currnt':''">
                                    <span>{{service.title}}</span>
                                    <span class="float-end ms-5">${{service.cost}}</span>
                                    <span class="float-end">{{service.duration}} min</span>
                                    <input v-model="service.selected" :true-value="true" :false-value="false" type="checkbox" name="checkbox" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>                        
                        </div>
                    </div>
                </div>


                <!-- <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Select Professional </label>
                        <select v-model="walkin_.professional" :class="[v$.walkin_.professional.$error ? 'border-danger': '']" class="form-control" v-on:change="getServices">
                            <option value="">Choose employee</option>
                            <option v-for="employee in employees" :key="employee.id" :value="employee">{{employee.user.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div v-for="(service,index) in walkin_.services" :key="service.id" class="form-group col-sm-6 col-md-6 col-lg-3 mb-0">
                        <label><span v-if="index == 0">Select Services</span> </label>
                        <label class="con-company" :class="service.selected?'currnt':''">
                            {{service.title}}
                            <input v-model="service.selected" :class="[v$.walkin_.services.$error ? 'border-danger': '']" :true-value="true" :false-value="false" type="checkbox" name="checkbox" />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="row mt-3" v-show="walkin_.services.length > 0">
                    <div class="col-12">
                        <div class="leads-table-area report-table-area" style="box-shadow: none;">
                            <table class="display" id="servicesTable">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th>Duration</th>
                                        <th>Cost</th>
                                    </tr>
                                </thead>
                                <tbody v-if="this.walkin_.services.length > 0">
                                    <tr v-for="(serv,index) in selectedServices" :key="serv.id">
                                        <td>{{index+1}}</td>
                                        <td>{{serv.title}}</td>
                                        <td>{{serv.duration}} min</td>
                                        <td>${{serv.cost}}</td>
                                    </tr>
                                    <tr v-show="selectedServices.length == 0">
                                        <td colspan="4" class="text-center">No Service</td>
                                    </tr>
                                </tbody>
                                <tfoot v-if="this.walkin_.services.length > 0">
                                    <tr v-show="selectedServices.length > 0">
                                        <th colspan="3" class="text-right">Total:</th>
                                        <th>${{selectedServicesSum}}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div> -->
                
                <!-- <div class="form-row mb-4">
                    <div class="form-group col-sm-6 col-md-6 col-lg-6">
                        <label>Status </label>
                        <select v-model="walkin_.status" class="form-control">
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>
                </div> -->
            </form>
            <div class="row">
                <div class="col-12">
                    <h5>Summary</h5>
                </div>
                <!-- walkin_.services.length > 0 -->
                <div class="col-12">
                    <div class="leads-table-area report-table-area mb-4" style="box-shadow: none;">
                        <table class="display" id="servicesTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Duration</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody v-if="this.walkin_.services.length > 0">
                                <tr v-for="(serv,index) in selectedServices" :key="serv.id">
                                    <td>{{index+1}}</td>
                                    <td>{{serv.title}}</td>
                                    <td>{{serv.duration}} min</td>
                                    <td>${{serv.cost}}</td>
                                </tr>
                                <tr v-show="selectedServices.length == 0">
                                    <td colspan="4" class="text-center">No Service</td>
                                </tr>
                            </tbody>
                            <tfoot v-if="this.walkin_.services.length > 0">
                                <tr v-show="selectedServices.length > 0">
                                    <th colspan="3" class="text-end">Total:</th>
                                    <th>${{selectedServicesSum}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="form-row  mb-4">
                <div class="form-group col-12">
                    <label>Additional Note <small>(optional)</small> </label>
                    <textarea v-model="walkin_.note" class="form-control" placeholder="Do you have any special requests or ideas to share with your service provider?" rows="4" style="resize: none"></textarea>
                </div>
            </div>
        </div>

        <div class="modal-footer campaign-footer d-flex">
            <router-link :to="{name:'BusinessDashboard'}" class="add-btn"> Cancel</router-link>
            <a href="#" class="rem-btn" v-on:click.prevent="addWalkIn"> Add Walk In</a>
        </div>
    </div>


    <!-- Add Customer Modal -->
    <div class="modal fade" id="addCustomerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header border-bottom">
                    <h5 class="modal-title fs-4" id="exampleModalLabel">Add Customer</h5>
                    <a class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img class="img-fluid" src="@/assets/images/dashboard/close.svg" alt=""></span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="col-12 col-md-8">
                            <div class="form-group">
                                <label>Email / Phone <span style="font-size:11px">(Search the customer before you proceed)</span></label>
                                <div class="d-flex email-search">
                                    <input v-model="search.text" :class="[v$.search.text.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Enter email / phone">
                                    <a v-on:click.prevent="searchUser" href="#" class="rem-btn" >Search</a>
                                </div>
                                <p class="no_user_found text-danger small" style="display: none">No User Found! Please try again.</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>First Name</label>
                                <div class="d-flex email-search">
                                    <input v-model="customer_.first_name" :class="[v$.customer_.first_name.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="John">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Last Name</label>
                                <div class="d-flex email-search">
                                    <input v-model="customer_.last_name" :class="[v$.customer_.last_name.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Doe">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Email</label>
                                <div class="d-flex email-search">
                                    <input v-model="customer_.email" :class="[v$.customer_.email.$error ? 'border-danger': '']" type="email" class="form-control" placeholder="example@gmail.com">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Phone Number</label>
                                <div class="d-flex email-search">
                                    <input v-model="customer_.phone" :class="[v$.customer_.phone.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="+1213143132322">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <label>Gender </label>
                            <select v-model="customer_.gender" :class="[v$.customer_.gender.$error ? 'border-danger': '']" class="form-control">
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#" class="add-btn" data-bs-dismiss="modal" aria-label="Close"> Cancel</a>
                    <button type="button" class="rem-btn" v-on:click="addCustomer">Add Customer</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Customer Modal -->
</template>
<script>
    import axios from 'axios'
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import useVuelidate from '@vuelidate/core'
    import { required, integer, requiredIf } from '@vuelidate/validators'
    import { useToast } from "vue-toastification"
    import VueTimepicker from 'vue3-timepicker'
    import 'vue3-timepicker/dist/VueTimepicker.css'

    import $ from "jquery";
    import "datatables.net";
    import "datatables.net-responsive";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

    export default {
        components: {
            Datepicker,
            VueTimepicker
        },
        setup () {
            const toast = useToast();
            return { v$: useVuelidate(),toast }
        },
        data() {
            return {
                search:{
                    text:'',
                    customer:''
                },
                customer_:{
                    first_name:'',
                    last_name:'',
                    email:'',
                    phone:'',
                    gender:'',
                },
                walkin_:{
                    user:'',
                    date:null,
                    time_slot:null,
                    is_deal:false,
                    services:[],
                    professional:{},
                    render_location:1,
                    rendered_address:'',
                    rendered_address_apt:'',
                    booking_type:'2',
                    note:'',
                    booking_source:'walkin',
                    business_id: this.$storage.getStorageSync('business').active_business.id
                },
                customers:[],
                employees:[]
            };
        },
        validations () {
            return {
                walkin_:{
                    user:{ required },
                    date:{ requiredIfFoo: requiredIf(this.walkin_.booking_type == 2) },
                    time_slot:{ requiredIfFoo: requiredIf(this.walkin_.booking_type == 2) },
                    //date:{ required },
                    //time_slot:{ required },
                    services:{ required },
                    //customer:{ required },
                    professional:{ required },
                    render_location:{ required },
                    rendered_address:{ requiredIfFoo: requiredIf(this.walkin_.render_location == 2) },
                    booking_type:{ required }
                },
                search:{
                    text:{ required },
                },
                customer_:{
                    first_name:{ required },
                    last_name:{ required },
                    email:{ required },
                    phone:{ required, integer },
                    gender:{ required }
                }
            }
        },
        created() {
            this.getEmployees()
            this.usersList()
        },
        methods:{
            getEmployees(){
                let thiss = this
                axios.get('employees_list',{params:{profession:true}}).then(function (response) {
                    thiss.employees = response.data.data
                    // console.log(thiss.employees)
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            },
            usersList(){
                let thiss = this
                axios.get('usersList').then(function (response) {
                    thiss.customers = response.data
                    // console.log(thiss.customers)
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            },
            // getCustomerByID(id){
            //     let thiss = this
            //     axios.get('get_customer_by_id/'+id).then(function (response) {
            //         if (response.data.status == 'success') {
            //             thiss.walkin_.user.picture = response.data.data.picture
            //             thiss.walkin_.user.first_name = response.data.data.first_name
            //             thiss.walkin_.user.last_name = response.data.data.last_name
            //             thiss.walkin_.user.email = response.data.data.email
            //             thiss.walkin_.user.phone = response.data.data.phone
            //             thiss.walkin_.user.gender = response.data.data.gender
            //         }
            //     })
            //     .catch(error => {
            //         if (error.response) {
            //             console.log(error.response.data.message)
            //         }
            //     });
            // },
            searchUser(){
                this.v$.search.$touch()
                if (!this.v$.search.$error){
                    let thiss = this
                    axios.get('search_user_by_email_or_phone/'+thiss.search.text).then(function (response) {
                        if (response.data.status == 'success') {
                            if (response.data.data != 'No User Found.') {
                                thiss.customer_.first_name = response.data.data.first_name
                                thiss.customer_.last_name = response.data.data.last_name
                                thiss.customer_.email = response.data.data.email
                                thiss.customer_.phone = response.data.data.phone
                                thiss.customer_.gender = response.data.data.gender
                                thiss.search.customer = response.data.data
                                $('.no_user_found').hide('slow')
                            }else{
                                $('.no_user_found').show('slow')
                                thiss.customer_.first_name = ''
                                thiss.customer_.last_name = ''
                                thiss.customer_.email = ''
                                thiss.customer_.phone = ''
                                thiss.customer_.gender = ''
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.data.message)
                        }
                    });
                }
            },
            addCustomer(){
                //this.walkin_.user.picture = this.customer_.picture
                //this.walkin_.user.first_name = this.customer_.first_name
                //this.walkin_.user.last_name = this.customer_.last_name
                //this.walkin_.user.email = this.customer_.email
                //this.walkin_.user.phone = this.customer_.phone
                //this.walkin_.user.gender = this.customer_.gender
                //this.walkin_.customer = this.search.customer
                this.search.text = ''
                this.customer_.first_name = ''
                this.customer_.last_name = ''
                this.customer_.email = ''
                this.customer_.phone = ''
                this.customer_.gender = ''
                $('#addCustomerModal').modal('hide');
                /*this.v$.customer_.$touch()
                if (!this.v$.customer_.$error){
                    let thiss = this
                    axios.get('add_customer/'+thiss.customer_).then(function (response) {
                        console.log(response.data)
                        if (response.data.status == 'success') {
                            this.walkin_.user.picture = this.customer_.picture
                            this.walkin_.user.first_name = this.customer_.first_name
                            this.walkin_.user.last_name = this.customer_.last_name
                            this.walkin_.user.email = this.customer_.email
                            this.walkin_.user.phone = this.customer_.phone
                            this.walkin_.user.gender = this.customer_.gender
                            this.walkin_.customer = this.search.customer
                            this.search.text = ''
                            this.customer_.first_name = ''
                            this.customer_.last_name = ''
                            this.customer_.email = ''
                            this.customer_.phone = ''
                            this.customer_.gender = ''
                            $('#addCustomerModal').modal('hide');
                        }
                        console.log(thiss.walkin_)
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error.response.data.message)
                        }
                    });
                }*/
            },
            changeType(value){
                this.walkin_.booking_type = value
                this.walkin_.note = ''
                this.walkin_.professional = {}
                this.walkin_.services = []
            },
            getServices(employee,platform){
                this.walkin_.professional = employee
                let thiss = this
                axios.post('employeeServices',{id: employee.user_id,business_id:this.$storage.getStorageSync('business').active_business.id,platform:platform}).then(function (response) {
                    console.log(response.data.data)
                    if (response.data.status == 'success') {
                        thiss.walkin_.services = response.data.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            },
            addWalkIn(){
                console.log(this.walkin_)
                this.v$.walkin_.$touch()
                if (!this.v$.walkin_.$error){
                    console.log("Validation Done")
                    let url = ''
                    if (this.walkin_.booking_type == 2) {
                        url = 'book_now'
                    }else if(this.walkin_.booking_type == 1) {
                        url = 'join_queue'
                    }else{
                        this.toast.error("Invalid Type.",{position: "top-right"});
                        return;
                    }
                    let thiss = this
                    axios.post(url,this.walkin_).then(function (response) {
                        console.log(response.data)
                        if (response.data.status == 'success') {
                            thiss.toast.success("Walk In added successfully!")
                            if (this.walkin_.booking_type == 2) {
                                thiss.$router.push({ name: 'appointments',params:{type:'today'}})
                            }else if(this.walkin_.booking_type == 1) {
                                thiss.$router.push({ name: 'appointments',params:{type:'queue'}})
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                }
            },
            // getEmployeeServices(emp){
            //     this.walkin_.professional = emp
            //     let thiss = this
            //     axios.post('queueServices',{'user_id':emp.user.id,'business':1}).then(function (response) {
            //         if (response.data.status == 'success') {
            //             var data = []
            //             response.data.data.forEach((serv) => {
            //                 data.push({
            //                     title:serv.service.title,
            //                     service_id: serv.service_id,
            //                     employee_service_id: serv.id,
            //                     category_id: serv.service.category_id,
            //                     cost: serv.cost,
            //                     duration: serv.duration,
            //                     selected : false,
            //                 })
            //             });
            //             thiss.walkin_.services = data
            //             console.log(thiss.walkin_.services)
            //         }
            //     })
            //     .catch(error => {
            //         if (error.response) {
            //             console.log(error.response.data.message)
            //         }
            //     });
            // },
            // filteredServices(){
            //     return this.services.filter(i => i.col === 'one')
            // },
            // getEmployeQueue(employeeId,userId){
            //     let thiss = this
            //     axios.post('get_employee_queues',{'provider_id':employeeId,'user_id':userId,'slug':this.$route.params.slug}).then(function (response) {
            //         if (response.data.status == 'success') {
            //             thiss.queues = response.data.data
            //             console.log(thiss.queues)
            //         }
            //     })
            //     .catch(error => {
            //         if (error.response) {
            //             console.log(error.response.data.message)
            //         }
            //     });
            // }
        },
        mounted() {
            $("#servicesTable").addClass("nowrap").dataTable({
                ordering: false,
                searching: false, 
                paging: false, 
                info: false,
                responsive: true,
                fixedHeader: true,
                language : {
                    "zeroRecords": " "
                }
            });
        },
        computed: {
            selectedServices: function () {
                return this.walkin_.services.filter(i => i.selected === true)
            },
            selectedServicesSum: function () {
                var data = this.walkin_.services.filter(i => i.selected === true)
                return data.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0).toFixed(2);
                //acc + item.value, 0
            }
        }
    }
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style type="text/css">
    .email-search a{
        border-radius: .25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }
    .multiselect-tag.is-user {
        padding: 5px 8px;
        border-radius: 22px;
        background: #35495e;
        margin: 3px 3px 8px;
    }
    .multiselect-tag.is-user img {
        width: 18px;
        border-radius: 50%;
        height: 18px;
        margin-right: 8px;
        border: 2px solid #ffffffbf;
    }
    .multiselect-tag.is-user i:before {
        color: #ffffff;
        border-radius: 50%;;
    }
    .user-image {
        margin: 0 6px 0 0;
        border-radius: 50%;
        height: 22px;
    }
    .vue__time-picker .controls .char{
        margin-top: 4px
    }
    .vue__time-picker .controls{
        right: -275px
    }
    .vue__time-picker input.vue__time-picker-input{
        width: 445px
    }
    .email-deatils{
        overflow: unset
    }

    .emp-card {
        cursor: pointer;
        border: 1px solid #f1f1f1;
        border-radius: .5rem
    }
    .emp-card svg {
        display: none
    }
    .emp-card.active {
        border: 2px solid #6e317a;
        background: #6e317a0f
    }
    .emp-card.active svg {
        display: block;
        position: absolute;
        right: -7px;
        top: -7px;
        color: #6e317a;
        z-index: 23;
        background: #fff
    }
    .booking-vendor-img {
        background-position: 50% 50%;
        cursor: pointer;
        float: left;
        height: 60px;
        text-decoration: none solid rgb(0, 0, 238);
        text-size-adjust: 100%;
        width: 60px;
        border: 0px none rgb(0, 0, 238);
        border-radius: 100% 100% 100% 100%;
        flex: 0 0 auto;
        font: normal normal 300 normal 15px / 20px proxima-nova, sans-serif;
        margin: 0px 15px 0px 0px;
        outline: rgb(0, 0, 238) none 0px;
    }
    #DIV_10 {
        color: rgb(51, 51, 51);
        display: flex;
        min-height: auto;
        min-width: auto;
        text-decoration: none solid rgb(51, 51, 51);
        text-size-adjust: 100%;
        width: calc(100% - 95px);
        column-rule-color: rgb(51, 51, 51);
        align-items: flex-start;
        perspective-origin: 442.5px 47.5px;
        transform-origin: 442.5px 47.5px;
        caret-color: rgb(51, 51, 51);
        border: 0px none rgb(51, 51, 51);
        flex: 1 1 0%;
        flex-flow: column nowrap;
        font: normal normal 300 normal 15px / 20px proxima-nova, sans-serif;
        outline: rgb(51, 51, 51) none 0px;
    }
    #DIV_10 a span{
        font-size: 12px;
    }
    #A_11 {
        
        color: rgb(51, 51, 51);
        display: block;
        text-decoration: none solid rgb(51, 51, 51);
        text-size-adjust: 100%;
        border: 0px none rgb(51, 51, 51);
        font: normal normal 400 normal 18px / 20px proxima-nova, sans-serif;
        margin: 0px 0px 3px;
        outline: rgb(51, 51, 51) none 0px;
    }
    #DIV_12 {
        
        color: rgba(51, 51, 51, 0.8);
        min-height: auto;
        min-width: auto;
        text-decoration: none solid rgba(51, 51, 51, 0.8);
        text-size-adjust: 100%;
        width: 183.047px;
        column-rule-color: rgba(51, 51, 51, 0.8);
        perspective-origin: 91.5156px 10.5px;
        transform-origin: 91.5156px 10.5px;
        caret-color: rgba(51, 51, 51, 0.8);
        border: 0px none rgba(51, 51, 51, 0.8);
        font: normal normal 400 normal 15px / 20px proxima-nova, sans-serif;
        outline: rgba(51, 51, 51, 0.8) none 0px;
    }

    #SPAN_13 {
        
        color: rgb(149, 152, 154);
        display: inline-block;
        height: 20px;
        text-align: right;
        text-decoration: line-through solid rgb(149, 152, 154);
        text-size-adjust: 100%;
        width: 38.6406px;
        column-rule-color: rgb(149, 152, 154);
        perspective-origin: 19.3125px 10px;
        transform-origin: 19.3125px 10px;
        caret-color: rgb(149, 152, 154);
        border: 0px none rgb(149, 152, 154);
        font: normal normal 400 normal 12px / 20px proxima-nova, sans-serif;
        outline: rgb(149, 152, 154) none 0px;
    }
    #SPAN_14, #SPAN_15 {
        
        color: rgba(51, 51, 51, 0.8);
        text-decoration: none solid rgba(51, 51, 51, 0.8);
        text-size-adjust: 100%;
        column-rule-color: rgba(51, 51, 51, 0.8);
        perspective-origin: 0px 0px;
        transform-origin: 0px 0px;
        caret-color: rgba(51, 51, 51, 0.8);
        border: 0px none rgba(51, 51, 51, 0.8);
        font: normal normal 400 normal 14px / 20px proxima-nova, sans-serif;
        outline: rgba(51, 51, 51, 0.8) none 0px
    }
    .plan-box{
        /*background: #f9f4f9;*/
        padding: 15px;
        text-align: center;
        cursor: pointer;
        border: 2px solid #f9f4f9;
        border-radius: 15px
    }
    .plan-active{
        border-color: #9901a7
    }
    .plan-icon{
        width: 70px;
        margin: 0 auto;
        background: #fff;
        padding: 12px;
        border: 1px solid #dddcdd;
        border-radius: 10px
    }
    .plan-icon img{
        width: 100%
    }
    .plan-title{
        font-size: 20px;
        font-weight: 400;
        margin: 12px 0 0
    }
    .plan-box p{
        font-size: 13px;
        font-weight: 300;
        color: #838383;
        margin: 0
    }
    /*.plan-active .plan-title, .plan-active p{
        color: #fff;
    }*/
    .plan-active .plan-icon{
        position: relative
    }
    .plan-icon .check-mark{
        display: none
    }
    .plan-active .plan-icon .check-mark{
        position: absolute;
        top: 0;
        right: -2px;
        display: block;
        /* background: #16bc16;
        border-radius: 100%;
        width: 25px;
        height: 25px;
        padding: 1px; */
    }
    .thamnils{
        width: 60px;
        height: 60px;
        text-align: center;
        border: 2px solid #A2A8B8;
        border-radius: 50%
    }
    .thamnils img{
        width: 40px
    }
    .type-active{
        background-color: #6e317a;
        color: #ffffff
    }
    .type-active .thamnils{
        border: 2px solid #ffffff
    }
    .saved_payments{
        border-radius: .25rem
    }
    .saved_payments .card-header{
        padding: 10px;
        background-color: #f5f5f5
    }
    .saved_payments .card-body{
        padding: 1rem;
        margin-top: 0
    }
    .form-check-input{
        margin-left: 0;
        position: unset
    }
    .form-check-label{
        vertical-align: middle
    }
    table.dataTable.no-footer {
        border-bottom: 0
    }
    @media (max-width: 575px){
        .email-campaign{
            padding: 25px 15px
        }
    }
</style>